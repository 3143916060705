export enum TradingPartnerType{
  upstream = 'upstream',
  downstream = 'downstream',
  both = 'both'
}

export enum AddressType {
  physical = 'physical',
  mailTo = 'mail-to',
  billTo = 'bill-to',
  other = 'other',
}

export enum ContactType {
  phone = 'phone',
  fax = 'fax',
  email = 'email',
  cell = 'cell',
}
