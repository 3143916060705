import { logout } from '../../func';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import moment from 'moment/moment';
import M from 'materialize-css';
import { BsUpcScan } from 'react-icons/bs';
import { HiOutlineDocumentArrowDown } from 'react-icons/hi2';
import MimeTypes from '../../utilities/mimeTypes';
import { Link } from 'react-router-dom';
const { NODE_ENV } = process.env;

const OrderOverview = (props) => {
  const initSearchParams = () => ({
    customerId: 0,
    senderName: '',
    docDateStart: moment().startOf('week').format('YYYY-MM-DD'),
    docDateEnd: moment().format('YYYY-MM-DD'),
  });

  const ref = useRef({
    abortController: new AbortController(),
  });

  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState(initSearchParams());
  const [orderList, setOrderList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [downloadInProgressIds, setDownloadInProgressIds] = useState([]);

  const getAuthData = () => JSON.parse(localStorage.getItem('auth_data'));

  const initMaterialize = () => {
    M.FormSelect.init(document.querySelectorAll('select'));
  };

  const search = (customerId) => {
    setLoading(true);
    const time = moment().format(' HH:mm:ss');
    const { docDateStart, docDateEnd, senderName } = searchParams;

    Axios.get('/api/v1/order/read/many/search', {
      params: {
        ...getAuthData(),
        senderName: senderName ? senderName.trim() : '',
        docDateStart: docDateStart + ' 00:00:00',
        docDateEnd: docDateEnd + time,
        customerId: customerId ? customerId : searchParams.customerId,
      },
      signal: ref.current.abortController.signal,
    })
      .then((result) => setOrderList(result.data))
      .catch(logout)
      .finally(() => setLoading(false));
  };

  const getCustomerList = () => {
    Axios.get('/api/v1/customer/read/many/by/userid', {
      params: getAuthData(),
      signal: ref.current.abortController.signal,
    })
      .then((result) => {
        const customerId = result.data[0]?.id;

        setSearchParams((p) => ({ ...p, customerId: result.data[0].id }));
        setCustomerList(result.data);
        initMaterialize();
        search(customerId);
      })
      .catch(logout);
  };

  useEffect(() => {
    getCustomerList();
    return NODE_ENV === 'production'
      ? () => ref.current?.abortController.abort()
      : undefined;
  }, []);

  const onChange = ({ target: { id, value } }) =>
    setSearchParams((p) => ({ ...p, [id]: value }));

  const download = (documentId) => {
    if (downloadInProgressIds.includes(documentId)) return;

    ref.current.abortController = new AbortController();
    setDownloadInProgressIds((p) => [...p, documentId]);

    Axios.get('/api/v1/file/download/epcisdoc', {
      params: {
        ...getAuthData(),
        documentId,
      },
      signal: ref.current.abortController?.signal,
    })
      .then((result) => {
        const { document: doc, buffer } = result.data;

        const uint8Array = Uint8Array.from(buffer.data);
        const blob = new Blob([uint8Array]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.hidden = true;
        link.href = url;
        link.setAttribute(
          'download',
          `epcis_doc_from_${doc.name}_${moment(doc.timeStamp).format(
            'YYMMDD'
          )}.${MimeTypes.getExtensions(doc.contentType)?.[0] || 'txt'}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(logout)
      .finally(() =>
        setDownloadInProgressIds((p) => p.filter((id) => id !== documentId))
      );
  };

  return (
    <div>
      <div className="row">
        <div className="input-field col s12 m3 l2">
          <select
            id="customerId"
            onChange={onChange}
            value={searchParams.customerId}
          >
            {customerList?.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
          <label htmlFor="customerId">Recipient Name</label>
        </div>
        <div className="input-field col s12 m2">
          <input
            id="senderName"
            type="text"
            onChange={onChange}
            value={searchParams.senderName}
          />
          <label htmlFor="senderName">Sender Name</label>
        </div>
        <div className="input-field col s12 m2">
          <input
            id="docDateStart"
            type="date"
            onChange={onChange}
            value={searchParams.docDateStart}
          />
          <label htmlFor="docDateStart">Date Start</label>
        </div>
        <div className="input-field col s12 m2">
          <input
            id="docDateEnd"
            type="date"
            onChange={onChange}
            value={searchParams.docDateEnd}
          />
          <label htmlFor="docDateEnd">Date End</label>
        </div>
        <div className="input-field col s12 m2">
          <a
            style={{ position: 'relative', top: '8px' }}
            href="/"
            className="btn-small blue white-text waves-effect waves-light col s12"
            onClick={(e) => {
              e.preventDefault();
              search();
            }}
            disabled={loading ? 'disabled' : ''}
          >
            {loading ? 'Searching...' : 'Search Orders'}
          </a>
        </div>
      </div>
      <div className="row">
        {orderList?.length ? (
          <table className="highlight">
            <thead>
              <tr>
                <th>Recipient</th>
                <th>Recipient GLN</th>
                <th>Sender</th>
                <th>Date of Receipt</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orderList?.map((o) => (
                <tr key={o.documentId}>
                  <td style={{ padding: '2px' }}>{o.customerLocationName}</td>
                  <td style={{ padding: '2px' }}>{o.globalLocationNumber}</td>
                  <td style={{ padding: '2px' }}>{o.senderName}</td>
                  <td style={{ padding: '2px' }}>
                    {moment(o.timeStamp).format('MM/DD/YY HH:mm')}
                  </td>
                  <td
                    style={{
                      padding: '2px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Link
                      to={`/orders/receive/${o.documentId}`}
                      className="black-text"
                    >
                      <BsUpcScan
                        style={{
                          fontSize: '1.3em',
                          position: 'relative',
                          top: '3px',
                          cursor: 'pointer',
                        }}
                      />
                    </Link>
                    {downloadInProgressIds?.includes(o.documentId) ? (
                      <div className="preloader-wrapper tiny active">
                        <div className="spinner-layer spinner-blue-only">
                          <div className="circle-clipper left">
                            <div className="circle"></div>
                          </div>
                          <div className="gap-patch">
                            <div className="circle"></div>
                          </div>
                          <div className="circle-clipper right">
                            <div className="circle"></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: '1.3em',
                          position: 'relative',
                          top: '3px',
                          cursor: 'pointer',
                        }}
                        onClick={() => download(o.documentId)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h6
            className="orange-text"
            style={{ position: 'relative', left: '80px' }}
          >
            <i>
              No results. Please modify the search parameters and search again.
            </i>
          </h6>
        )}
      </div>
    </div>
  );
};

export default OrderOverview;
