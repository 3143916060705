import { useEffect, useState, useLayoutEffect, useCallback } from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import api from '../../common/api/index.ts';

const TradingPartner = (props) => {
  const initValues = () => ({
    name: '',
    globalLocationNumber: '',
    stateId: '',
    type: '',
  });

  const [values, setValues] = useState(initValues());
  const [stateList, setStateList] = useState([]);
  const [tradingPartnerList, setTradingPartnerList] = useState([]);
  const [loadingStateList, setLoadingStateList] = useState(false);

  const onChange = ({ target: { id, value } }) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const getStateList = async () => {
    const stateList = await api.state.read.all();
    setStateList(stateList);
  };

  const getTradingPartnerList = async (data) => {
    const tradingPartnerList =
      await api.tradingPartner.read.many.tradingPartners.search(data);
    return tradingPartnerList;
  };

  useEffect(() => {
    getStateList();
    search();
  }, []);

  useLayoutEffect(() => {
    M.FormSelect.init(document.querySelectorAll('select'));
    return () =>
      document
        .querySelectorAll('select')
        .forEach((el) => M.FormSelect.getInstance(el)?.destroy());
  }, [stateList]);

  const search = useCallback(
    async (event) => {
      event?.preventDefault();
      const tradingPartnerList = await getTradingPartnerList(values);
      setTradingPartnerList(tradingPartnerList);
    },
    [values]
  );

  return (
    <div style={{ paddingTop: '20px' }}>
      <div className="row" style={{ marginBottom: '40px' }}>
        <div className="input-field col s12 m2">
          <input
            id="name"
            type="text"
            value={values.name}
            onChange={onChange}
          />
          <label htmlFor="name">Name</label>
        </div>
        <div className="input-field col s12 m2">
          <input
            id="globalLocationNumber"
            type="text"
            value={values.gln}
            onChange={onChange}
          />
          <label htmlFor="globalLocationNumber">GLN</label>
        </div>
        <div className="input-field col s12 m3">
          <select
            id="stateId"
            value={values.stateId}
            onChange={onChange}
            disabled={loadingStateList}
          >
            <option value=""></option>
            {stateList?.map((state) => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
          <label htmlFor="stateId">State</label>
        </div>
        <div className="input-field col s12 m3">
          <select id="type" value={values.type} onChange={onChange}>
            <option value=""></option>
            <option value="upstream">Upstream</option>
            <option value="downstream">Downstream</option>
            <option value="both">Both</option>
          </select>
          <label htmlFor="type">Type</label>
        </div>
        <div className="input-field col s12 m2">
          <a
            href="/"
            onClick={search}
            className="btn-small col s12 blue white-text waves-effect waves-light"
          >
            Search
          </a>
        </div>
      </div>
      <div className="card">
        <div className="card-content">
          <div className="row">
            <div className="col s12 m4 offset-m8">
              <Link
                to="/tradingpartner/create"
                className="btn-small blue white-text waves-effect waves-light col s12"
              >
                Add Trading Partner
              </Link>
            </div>
          </div>
          <div className="row">
            <table className="highlight">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>GLN</th>
                  <th>City, State</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {tradingPartnerList?.map(
                  ({ id, name, type, addresses, globalLocationNumbers }) => (
                    <tr key={id}>
                      <td style={{ padding: '2px' }}>
                        <Link to={`/tradingpartner/${id}`}>{name}</Link>
                      </td>
                      <td style={{ padding: '2px' }}>
                        {globalLocationNumbers?.[0]}
                      </td>
                      <td style={{ padding: '2px' }}>{addresses?.[0]}</td>
                      <td style={{ padding: '2px' }}>{type}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingPartner;
