import customer from './customer.ts';
import state from './state.ts';
import tradingPartner from './tradingPartner.ts';

const api = {
  state,
  tradingPartner,
  customer
};

export default api;
