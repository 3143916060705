import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { TradingPartnerContact } from '../../../common/models';
import { ContactType } from '../../../common/enums.ts';

interface props {
  contacts: Partial<TradingPartnerContact>[];
  setContacts: Dispatch<SetStateAction<Partial<TradingPartnerContact>[]>>;
}

const Contacts = ({ contacts, setContacts }: props) => {
  const modal = useRef<HTMLDivElement>(null);
  const select = useRef<HTMLSelectElement>(null);

  const initValues = (): Partial<TradingPartnerContact> => ({
    firstName: '',
    lastName: '',
    title: '',
    contactDetails: [],
  });

  const initDetailValues = () => ({
    contactType: ContactType.phone,
    contactDetail: '',
  });

  const [values, setValues] = useState(initValues());
  const [detailValues, setDetailValues] = useState(initDetailValues());

  useLayoutEffect(() => {
    M.Modal.init(document.querySelectorAll('#contacts-modal'));
    M.FormSelect.init(document.querySelectorAll('#contactType'));
    const modalElement = modal.current;
    const selectElement = select.current;

    return () => {
      if (modalElement) M.Modal.getInstance(modalElement)?.destroy();
      if (selectElement) M.FormSelect.getInstance(selectElement)?.destroy();
    };
  }, []);

  const openModal = () => {
    if (modal.current) M.Modal.getInstance(modal.current)?.open();
  };

  const closeModal = () => {
    if (modal.current) M.Modal.getInstance(modal.current)?.close();
  };

  const addContact = (event: MouseEvent) => {
    event?.preventDefault();
    openModal();
  };

  const onChange = ({
    target: { value, id },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const detailChange = ({
    target: { id, value },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setDetailValues((prev) => ({ ...prev, [id]: value }));

  const deleteContactDetail = (
    event: MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event?.preventDefault();
    const { contactDetails } = values;

    if (contactDetails) {
      contactDetails?.splice(index, 1);
      setValues((prev) => ({ ...prev, contactDetails: [...contactDetails] }));
    }
  };

  const addContactDetail = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    const { contactDetails } = values;

    if (contactDetails) {
      contactDetails.push({
        contactId: 0,
        ...detailValues,
      });

      setValues((prev) => ({ ...prev, contactDetails: [...contactDetails] }));
      setDetailValues(initDetailValues());
    }
  };

  const saveContact = () => {
    setContacts((prev) => [...prev, values]);
    closeModal();
    setValues(initValues());
    setDetailValues(initDetailValues());
  };

  const deleteContact = (
    event: MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event?.preventDefault();
    contacts.splice(index, 1);

    setContacts([...contacts]);
  };

  return (
    <>
      <div className="row">
        <h5>Contacts</h5>
        <div className="divider" />
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>First</th>
              <th>Last</th>
              <th>Title</th>
              <th>Contact Info</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {contacts?.map((contact, index) => (
              <tr key={index}>
                <td style={{ padding: '2px' }}>{contact.firstName}</td>
                <td style={{ padding: '2px' }}>{contact.lastName}</td>
                <td style={{ padding: '2px' }}>{contact.title}</td>
                <td style={{ padding: '2px' }}>
                  {contact.contactDetails
                    ?.map((deet) => deet.contactDetail)
                    .join(', ')}
                </td>
                <td style={{ padding: '2px' }}>
                  <a href="/" onClick={(event) => deleteContact(event, index)}>
                    Delete
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col s12 m3 offset-m9">
          <a href="/" className="right" onClick={addContact}>
            Add Contact
          </a>
        </div>
      </div>
      <div id="contacts-modal" ref={modal} className="modal">
        <div className="modal-content">
          <div className="row">
            <h6 className="center">Add Contact</h6>
            <div className="divider" />
          </div>
          <div className="row">
            <div className="input-field col s12 m6">
              <input
                id="firstName"
                type="text"
                value={values.firstName}
                onChange={onChange}
              />
              <label htmlFor="firstName">First Name</label>
            </div>
            <div className="input-field col s12 m6">
              <input
                id="lastName"
                type="text"
                value={values.lastName}
                onChange={onChange}
              />
              <label htmlFor="lastName">Last Name</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12 m4">
              <input
                id="title"
                type="text"
                value={values.title}
                onChange={onChange}
              />
              <label htmlFor="title">Job Title</label>
            </div>
          </div>
          <div className="row" style={{ marginBottom: '40px' }}>
            <table className="highlight">
              <thead>
                <tr>
                  <th>Contact Type</th>
                  <th>Detail</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values?.contactDetails?.map((detail, index) => (
                  <tr key={index}>
                    <td style={{ padding: '2px' }}>{detail.contactType}</td>
                    <td style={{ padding: '2px' }}>{detail.contactDetail}</td>
                    <td>
                      <a
                        href="/"
                        onClick={(event) => deleteContactDetail(event, index)}
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="input-field col s12 m4">
              <select
                ref={select}
                id="contactType"
                value={detailValues.contactType}
                onChange={detailChange}
              >
                <option value={ContactType.phone}>{ContactType.phone}</option>
                <option value={ContactType.cell}>{ContactType.cell}</option>
                <option value={ContactType.email}>{ContactType.email}</option>
                <option value={ContactType.fax}>{ContactType.fax}</option>
              </select>
              <label htmlFor="contactType">Contact Type</label>
            </div>
            <div className="input-field col s12 m4">
              <input
                id="contactDetail"
                type="text"
                value={detailValues.contactDetail}
                onChange={detailChange}
              />
              <label htmlFor="contactDetail">{detailValues.contactType}</label>
            </div>
            <div className="input-field col s12 m3 offset-m1">
              <a href="/" onClick={addContactDetail}>
                Add Detail
              </a>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="divider" />

          <div className="row">
            <div className="col s12 m3 offset-m6">
              <button
                onClick={saveContact}
                className="btn-small blue white-text waves-effect waves-light col s12"
              >
                Save Contact
              </button>
            </div>
            <div className="col s12 m3">
              <button
                onClick={closeModal}
                className="btn-small blue white-text waves-effect waves-light col s12"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
