const endpoints = {
  customer: {
    create: {},
    read: {
      many: {
        by: {
          userId: () => `/api/v1/customer/read/many/by/userid`,
        },
      },
    },
    update: {},
    delete: {},
  },
  state: {
    read: {
      all: () => `/api/v1/state/read/all`,
    },
  },
  tradingPartner: {
    create: {
      one: () => `/api/v1/tradingpartner/create/one`,
      tradingPartnerGlobalLocationNumber: () =>
        `/api/v1/tradingpartner/create/tradingpartnergloballocationnumber`,
      tradingPartnerAddress: () =>
        `/api/v1/tradingpartner/create/tradingpartneraddress`,
      tradingPartnerContact: () =>
        `/api/v1/tradingpartner/create/tradingpartnercontact`,
      tradingPartnerContactDetail: () =>
        `/api/v1/tradingpartner/create/tradingpartnercontactdetail`,
      tradingPartnerAccountNumber: () =>
        `/api/v1/tradingpartner/create/tradingpartneraccountnumber`,
    },
    read: {
      one: {
        tradingPartner: {
          by: {
            id: () => `/api/v1/tradingpartner/read/one/tradingpartner/by/id`,
          },
        },
      },
      many: {
        tradingPartners: {
          search: () =>
            `/api/v1/tradingpartner/read/many/tradingpartners/search`,
        },
      },
    },
    update: {
      one: {
        tradingPartner: () =>
          `/api/v1/tradingpartner/update/one/tradingpartner`,
        tradingPartnerGlobalLocationNumber: () =>
          `/api/v1/tradingpartner/update/one/tradingpartnergloballocationnumber`,
        tradingPartnerAddress: () =>
          `/api/v1/tradingpartner/update/one/tradingpartneraddress`,
        tradingPartnerContact: () =>
          `/api/v1/tradingpartner/update/one/tradingpartnercontact`,
        tradingPartnerAccountNumber: () =>
          `/api/v1/tradingpartner/update/one/tradingpartneraccountnumber`,
      },
    },
    delete: {
      one: {
        tradingPartnerGlobalLocationNumber: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartnergloballocationnumber`,
        tradingPartnerAddress: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartneraddress`,
        tradingPartnerContact: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartnercontact`,
        tradingPartnerContactDetail: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartnercontactdetail`,
        tradingPartnerAccountNumber: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartneraccountnumber`,
      },
    },
  },
};

export default endpoints;
