import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logout, toast } from '../../func';

export const fetch = async <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  body?: { [key: string]: any }
): Promise<T | null> => {
  try {
    const item = localStorage.getItem('auth_data');
    const authData = item ? JSON.parse(item) : {};
    const combinedBody = { ...authData, ...body };

    const requestConfig: AxiosRequestConfig = {
      url,
      method,
      params: ['GET', 'DELETE'].includes(method) ? combinedBody : null,
      data: ['PUT', 'POST'].includes(method) ? combinedBody : null,
    };
    const response: AxiosResponse = await Axios.request(requestConfig);
    if (response.data?.type === 'GLOBAL_TOAST') toast(response.data);

    return response.data as T;
  } catch (err) {
    logout(err);
    return null;
  }
};
