import M from 'materialize-css';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { LuPackageOpen } from 'react-icons/lu';
import { BsFiletypeXml } from 'react-icons/bs';
import { IoMdSwap } from 'react-icons/io';

const Sidenav = (props) => {
  const { logout } = props;

  const { pathname } = useLocation();
  const [user, setUser] = useState(null);


  useEffect(() => {
    getUser();

    M.Sidenav.init(document.querySelectorAll('.sidenav'));
    M.Sidenav.getInstance(document.querySelector('#side-navigation')).open();

    return () => {
      document.querySelectorAll('.sidenav').forEach((el) => {
        M.Sidenav.getInstance(el)?.destroy();
      });
    };
  }, []);

  const getUser = () => {
    Axios.get('/api/v1/user/read/one/by/id', {
      params: JSON.parse(localStorage.getItem('auth_data')),
    })
      .then((result) => setUser(result.data))
      .catch(logout);
  };

  return (
    <div style={{ margin: '0px', height: '0px', padding: '0px' }}>
      <ul id="side-navigation" className="sidenav sidenav-fixed">
        <li>
          <div className="user-view" style={{ backgroundColor: '#eeeeeeaa' }}>
            <p className="name" style={{ margin: '0px' }}>
              {user?.firstName} {user?.lastName}
              <span className="grey-text text-darken-1">
                {user?.title ? ` - ${user.title}` : ''}
              </span>
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                minHeight: '50px',
              }}
            >
              <a
                className="email"
                href="/"
                onClick={logout}
                style={{ padding: '0px', margin: '0px', display: 'inline' }}
              >
                Sign Out
              </a>
              <Link to="/settings/changepassword">
                <i className="material-icons black-text">settings</i>
              </Link>
            </div>
          </div>
        </li>
        <li style={{ marginTop: '20px' }}>
          <Link to="/" className={pathname === '/' ? 'red-text' : ''}>
            <i
              className={`material-icons ${pathname === '/' ? 'red-text' : ''}`}
            >
              home
            </i>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/tradingpartner"
            className={pathname.includes('/tradingpartner') ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname.includes('/tradingpartner') ? 'red-text' : ''
              }`}
            >
              <IoMdSwap />
            </i>
            Trading Partners
          </Link>
        </li>
        <li>
          <Link
            to="/orders"
            className={pathname === '/orders' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/orders' ? 'red-text' : ''
              }`}
            >
              <LuPackageOpen />
            </i>
            My Orders
          </Link>
        </li>
        <li>
          <Link
            to="/searchmeds"
            className={pathname === '/searchmeds' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/searchmeds' ? 'red-text' : ''
              }`}
            >
              content_paste
            </i>
            My Pedigrees
          </Link>
        </li>
        {/* <li>
                    <Link to="/inputmeds" className={pathname === '/inputmeds' ? 'red-text' : ''}>
                        <i className={`material-icons ${pathname === '/inputmeds' ? 'red-text' : ''}`}>add_circle_outline</i>
                        Create A Pedigree
                    </Link>
                </li> */}
        <li>
          <Link
            to="/sendapedigree"
            className={pathname === '/sendapedigree' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/sendapedigree' ? 'red-text' : ''
              }`}
            >
              content_paste_go
            </i>
            Send A Pedigree
          </Link>
        </li>
        <li>
          <Link
            to="/orderupload"
            className={pathname === '/orderupload' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/orderupload' ? 'red-text' : ''
              }`}
            >
              upload_file
            </i>
            Upload An Order Manually
          </Link>
        </li>
        {process.env.REACT_APP_NODE_ENV && (
          <li>
            <Link
              to="/demo/epcis/file/generator"
              className={
                pathname === '/demo/epcis/file/generator' ? 'red-text' : ''
              }
            >
              <i className="material-icons">
                <BsFiletypeXml
                  className={
                    pathname === '/demo/epcis/file/generator' ? 'red-text' : ''
                  }
                />
              </i>
              Demo EPCIS File Generator
            </Link>
          </li>
        )}
        <div style={{ marginTop: '40px' }} className="divider" />
        <li>
          <Link
            to="/settings/changepassword"
            className={pathname.includes('/settings') ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname.includes('/settings') ? 'red-text' : ''
              }`}
            >
              settings
            </i>
            Settings
          </Link>
        </li>{' '}
      </ul>
      <a
        href="/"
        data-target="side-navigation"
        className="sidenav-trigger white-text hide-on-large-only"
      >
        <i className="material-icons">menu</i>
      </a>
    </div>
  );
};

export default Sidenav;
